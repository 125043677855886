<template>
  <div class="">
    <b-card>
      <h4><i class="fa fa-edit"></i> Service Estimates - Line Item Per AFE / PO 1 <i class="ml-2 fa fa fa-question-circle" v-tooltip="'<p>1. Download CSV Estimates template first.</p><p>2. Fill the Estimates for service lines as they appear in the template.</p><p>3. Upload saved template.</p>'"></i></h4>
      <hr>
      <div class="row mb-4">
        <div class="col-md-4 col-xl-6 mt-3">
          <h5>Select Project <i class="ml-2 fa fa fa-question-circle" v-b-tooltip.hover title="You may select multiple projects"></i></h5>
          <multiselect @input="getAfes(), showVendors = false" v-model="projectid" placeholder="Search or add project" tag-placeholder="Search or add project" track-by="projectid" :options="projectData" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" :taggable="false" label="projectname">
          </multiselect>
        </div>
        <div class="col-md-4 col-xl-6 mt-3">
          <h5>Select AFE / PO</h5>
          <multiselect @input="showVendors = false, getVendors(), vendorEstimates = {}" v-model="afes" placeholder="Search or add afe" tag-placeholder="Search or add afe" track-by="pwcaid" :options="afeData" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" :taggable="false" label="afenum" :preselect-first="false">
          </multiselect>
        </div>
        <div class="col-md-4 col-xl-6 mt-3">
          <h5>Select Vendors</h5>
          <multiselect @input="getVendorServices()" v-model="vendorEstimates" placeholder="Search or add vendor" tag-placeholder="Search or add vendor" track-by="vid" :options="vendorListNew" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" :taggable="false" label="company_name" :preselect-first="false">
          </multiselect>
        </div>
      </div>
    </b-card>

    <!-- This part is for new Service Estimate Table -->
    <b-card v-if="vendorServiceListNew.length > 0">
      <div class="col-12 mb-4 mt-2 pt-3" v-if="vendorServiceListNew.length > 0">
        <h3 class="d-inline">{{vendorEstimates.company_name}}</h3>
        <vue-json-to-csv :labels="labels" :csv-title="'Import Estimates'" class="pull-right mr-2 btn btn-sm btn-blue"
            :json-data="sendFiltered()">
            Download CSV Estimates Template
            <i class="fa fa-download"></i>
        </vue-json-to-csv>
        <b-button variant="success" class="pull-right mr-2 btn-sm" v-b-modal.importEstimates> Import Estimates From CSV <i class="fa fa-plus"></i></b-button>

       <br/>
       <label for="">Search For Service:</label>
         <div class="row">
         <div class="col-md-6" id="search_estimate">
           <input type="text" class="form-control" v-model="searchBox" placeholder="Search">
              <button @click="Reload_getVendorServices()" class="btn btn-primary"><i class="fa fa-sync-alt"></i></button>
         </div>
       </div>

        <div class="table-responsive mt-2" v-if="!lodash.isEmpty(vendorEstimates, true)">
           <table class="table table-bordered">
                 <thead>
                  <tr class="table-success">
                      <td>Service Name</td>
                      <td colspan="2">UOM 1</td>
                      <td colspan="2">UOM 2</td>
                      <td colspan="2">UOM 3</td>

                       <td>Actual</td>
                       <td >Estimate</td>
                       <td>Difference</td>

                  </tr>
               </thead>

              <!-- #### This part is for dynamic three row fetching -->
                 <tbody v-for="vendorService in newList" v-bind:id="vendorService.ID">
                     <tr>
                     <td rowspan="3" style="width:20%;">
                         {{vendorService.servicename}} <br /> 
                         <b-badge v-b-popover.hover="'Flexible service have no certain pricing and uom.'" title="Information" v-if="vendorService.isflexible === 'Y'" variant="info" style="color: white;">Flexible Service</b-badge>
                       </td>
                       <td colspan="2">
                        UOM: <b>{{vendorService.uom1}}</b> <br/>
                        Price per unit: <b> {{formatPrice(vendorService.pu1)}}</b>
                       </td>

                       <td colspan="2">
                        UOM: <b> {{vendorService.uom2}} </b><br/>
                        Price per unit: <b> {{formatPrice(vendorService.pu2)}} </b>
                       </td>

                      <td colspan="2">
                        UOM: <b>{{vendorService.uom3}}  </b><br/>
                        Price per unit: <b> {{formatPrice(vendorService.pu3)}} </b>
                      </td>
                        <td rowspan="3">$ 
                          <span v-if="vendorService.isflexible === 'N'"> {{(vendorService.uom1total + vendorService.uom2total + vendorService.uom3total).toFixed(2)}} </span>
                           <span v-else> 
                             {{(vendorService.pu).toFixed(2)}}
                           </span>
                          </td>
                        <td rowspan="3">
                           <div v-for="estimatedService in vendorEstimateServiceList">
                              <span v-if="estimatedService.serviceid==vendorService.serviceid && estimatedService.afenum==afes.afenum">
                                 {{(estimatedService.qty1*vendorService.pu1 + estimatedService.qty2*vendorService.pu2 + estimatedService.qty3*vendorService.pu3).toFixed(2)}}
                              </span>
                             </div>
                        </td>
                        <td rowspan="3">
                           <div v-for="estimatedService in vendorEstimateServiceList">
                              <span v-if="estimatedService.serviceid==vendorService.serviceid && estimatedService.afenum==afes.afenum">
                                   <div style="color:red;font-weight:bold;" v-if="((vendorService.uom1total + vendorService.uom2total + vendorService.uom3total).toFixed(2) - (estimatedService.qty1*vendorService.pu1 + estimatedService.qty2*vendorService.pu2 + estimatedService.qty3*vendorService.pu3).toFixed(2)) < 0">
                                      {{((vendorService.uom1total + vendorService.uom2total + vendorService.uom3total) - (estimatedService.qty1*vendorService.pu1 + estimatedService.qty2*vendorService.pu2 + estimatedService.qty3*vendorService.pu3)).toFixed(2)}}
                                   </div>
                                   <div style="color:green; font-weight:bold;" v-else>
                                   {{((vendorService.uom1total + vendorService.uom2total + vendorService.uom3total) - (estimatedService.qty1*vendorService.pu1 + estimatedService.qty2*vendorService.pu2  + estimatedService.qty3*vendorService.pu3)).toFixed(2)}}
                                   </div>
                              </span>
                             </div>
                        </td>
                      </tr>
                      <tr class="table-info">
                       <td>Actual</td>
                       <td>Estimate</td>
                       <td>Actual</td>
                       <td>Estimate</td>
                       <td>Actual</td>
                       <td>Estimate</td>
                      </tr>
                      <tr>

                        <td>
                          <div v-if="vendorService.uom1total!=0">
                          Quantity: <b> {{vendorService.uom1qtn}}</b> <br/>
                          Total: <b> {{(vendorService.uom1total).toFixed(2)}}  </b>
                          </div>
                          <div v-else>
                          Quantity: <b> NA</b> <br/>
                          Total: <b> NA  </b>
                          </div>
                        </td>

                        <td >
                          <div v-if="vendorService.uom1!=null">
                          <div v-for="estimatedService in vendorEstimateServiceList">
                              <span v-if="estimatedService.serviceid==vendorService.serviceid && estimatedService.afenum==afes.afenum">
                                  Quantity: <b> {{estimatedService.qty1}}  </b> <br/>
                                  Total: <b>  {{(estimatedService.qty1*vendorService.pu1).toFixed(2)}} </b>
                              </span>
                           </div>
                          <button type="button" class="btn primary" style="background:#73C686; color:white;" v-b-modal.addEstimateUom1 @click="getNoUoms(vendorService)">+</button>
                         </div>
                        </td>

                       <td>
                         <div v-if="vendorService.uom2total!=0">
                          Quantity: <b> {{vendorService.uom2qtn}}</b> <br/>
                          Total: <b> {{(vendorService.uom2total).toFixed(2)}}  </b>
                          </div>
                          <div v-else>
                          Quantity: <b> NA</b> <br/>
                          Total: <b> NA  </b>
                          </div>
                        </td>
                        <td>
                           <div v-if="vendorService.uom2!=null">
                            <div v-for="estimatedService in vendorEstimateServiceList">
                              <span v-if="estimatedService.serviceid==vendorService.serviceid && estimatedService.afenum==afes.afenum">
                                  Quantity: <b> {{estimatedService.qty2}}  </b> <br/>
                                  Total: <b>  {{(estimatedService.qty2*vendorService.pu2).toFixed(2)}} </b>
                              </span>
                           </div>
                            <div>
                                  <button type="button" class="btn primary" style="background:#73C686; color:white;" v-b-modal.addEstimateUom2 @click="getNoUoms(vendorService)">+</button>
                            </div>
                           </div>
                          </td>
                         <td>
                         <div v-if="vendorService.uom3total!=0">
                          Quantity: <b> {{vendorService.uom3qtn}}</b> <br/>
                          Total: <b> {{(vendorService.uom3total).toFixed(2)}}  </b>
                          </div>
                          <div v-else>
                          Quantity: <b> NA</b> <br/>
                          Total: <b> NA  </b>
                          </div>
                        </td>
                        <td>
                           <div v-if="vendorService.uom3!=null">
                          <div v-for="estimatedService in vendorEstimateServiceList">
                              <span v-if="estimatedService.serviceid==vendorService.serviceid && estimatedService.afenum==afes.afenum">
                                  Quantity: <b> {{estimatedService.qty3}}  </b> <br/>
                                  Total: <b>  {{(estimatedService.qty3*vendorService.pu3).toFixed(2)}}</b>
                              </span>
                           </div>
                            <button type="button" class="btn primary" style="background:#73C686; color:white;" v-b-modal.addEstimateUom3 @click="getNoUoms(vendorService)">+</button>
                           </div>
                        </td>

                      </tr>
                     </span>
                </tbody>


                </table>
        </div>

      </div>
    </b-card>
    <!-- End of new service estimate table -->

        <b-modal id="addEstimateUom1" ref="addEstimateUom1" title="Add/Update Estimate 1" title-tag="h2" centered size="lg" hide-footer>
                <div class="row">
                  <div class="col-md-8">
                    <h5>Enter quanity:</h5>
                  </div>
                  <div class="col-md-12 form-group">
                    <input class="form-control" type="text" v-model="uom1Quantity">
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <b-button variant="success" @click="createEstimateUom1()" type="submit" block>Add <i class="fa fa-plus"></i></b-button>
                  </div>
                </div>
              </b-modal>

              <b-modal id="addEstimateUom2" ref="addEstimateUom2" title="Add/Update Estimate 2" title-tag="h2" centered size="lg" hide-footer>
                <div class="row">
                  <div class="col-md-8">
                    <h5>Enter quanity:</h5>
                  </div>
                  <div class="col-md-12 form-group">
                    <input class="form-control" type="text" v-model="uom2Quantity">
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <b-button variant="success" @click="createEstimateUom2()" type="submit" block>Add <i class="fa fa-plus"></i></b-button>
                  </div>
                </div>
              </b-modal>

              <b-modal id="addEstimateUom3" ref="addEstimateUom3" title="Add/Update Estimate 3" title-tag="h2" centered size="lg" hide-footer>
                <div class="row">
                  <div class="col-md-8">
                    <h5>Enter quanity:</h5>
                  </div>
                  <div class="col-md-12 form-group">
                    <input class="form-control" type="text" v-model="uom3Quantity">
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <b-button variant="success" @click="createEstimateUom3()" type="submit" block>Add <i class="fa fa-plus"></i></b-button>
                  </div>
                </div>
              </b-modal>



    <b-modal @shown="clearImport()" id="importEstimates" hide-footer ref="importEstimates" title="Import Estimates" title-tag="h2" centered size="lg">
      <div class="parse mt-2 mb-4 row">
      <div class="col-md-6">
        <h5>CSV Files Only</h5>
        <input
          class="mt-2"
          id="fileInput"
          type="file"
          ref="csvFile"
          @change="upload">
      </div>
      <div v-if="doc.length > 0" class="col-md-6">
        <h5>Import</h5>
        <button @click="importEstimates(doc)" class="btn btn-primary">Import</button>
      </div>

      <div class="col-md-12 mt-4" v-if="successServices.length > 0">
        <h5>Successfully Imported: </h5>
        <ul>
          <li v-for="success in successServices">
            {{success}}
          </li>
        </ul>
      </div>
      <div class="col-md-12 mt-4" v-if="serviceErrors.length > 0">
        <h5>Unsuccessful: </h5>
        <ul>
          <li v-for="error in serviceErrors">
            {{error}}
          </li>
        </ul>
      </div>
    </div>
    </b-modal>
  </div>
</template>
<script>

import Vue from 'vue'
import {ClientTable} from 'vue-tables-2'
Vue.use(ClientTable)
import Multiselect from 'vue-multiselect'
import {RotateSquare2} from 'vue-loading-spinner'
import VueJsonToCsv from 'vue-json-to-csv'

import Papa from 'papaparse';
import Blob from 'blob'
import FileSaver from 'file-saver'

export default {
  name: 'ServiceEstimates',
  components: {
    Multiselect,
    RotateSquare2,
    VueJsonToCsv,
    ClientTable,
  },
  data: function() {
    return {
      projectData: [],
      projectid: [],
      afeData: [],
      afes: [],
      uom1: "",
      uom2: "",
      uom3: "",
      uom1Quantity: 0,
      uom2Quantity: 0,
      uom3Quantity: 0,
      serviceid: 0,
      loading: false,
      showVendors: false,
      serviceData: [],
      vendorListNew:[],
      vendorServiceListNew:[],
      vendorEstimateServiceList:[],
      vendor:0,
      labels: {
        name: { title: 'Name'},
        uom1: { title: 'UOM 1'},
        estimateQuantity1: { title: 'Estimate Quantity 1'},
        uom2: { title: 'UOM 2'},
        estimateQuantity2: { title: 'Estimate Quantity 2'},
        uom3: { title: 'UOM 3'},
        estimateQuantity3: { title: 'Estimate Quantity 3'},
      },
      theme: 'bootstrap4',
      template: 'default',
      useVuex: false,
      columns: ['name', 'uom1', 'quantity1', 'uom2', 'quantity2', 'uom3', 'quantity3', 'total', 'edit'],
      options: {
        headings: {
          name: "Service Name",
          uom1: "UOM",
          uom2: "UOM2",
          uom3: "UOM3",
        },
        sortable: ['status', 'uwi', 'afes', 'lsdsurface', 'ptn'],
        filterable: ['status', 'uwi', 'afes', 'lsdsurface', 'ptn'],
        sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
      },
      editedLine: [],
      editedLineName: "",
      beforeEditCacheCatch: 0,
      doc: [],
      parseCsv: [],
      successServices: [],
      serviceErrors: [],
      wtsVendorList: [],
      vendorEstimates: [],
      estimateServicesData: [],
      estimateNoTotalServicesData: [],
      searchBox: ""
    }
  },
  computed: {
    newList: function(){
      if(this.searchBox != ""){

        return this.vendorServiceListNew.filter(x=>x.servicename.substring(0, 2).toLowerCase() == this.searchBox.substring(0, 2).toLowerCase());
      }
      else{
        return this.vendorServiceListNew
      }
    }
  },
  methods: {
    getAfes(){
      this.afes = [];
      this.afeData = [];
      if(this.$session.get('user') == 'pm'){
        this.$axios.get('/wts/get/afes/' + this.projectid.projectid).then(response => {
          this.afeData = response.data.result
        })
      } else {
        this.$axios.get('/manager/get/wts_afe/' + this.projectid.projectid + '/' + this.projectid.companyid).then(response => {
          this.afeData = response.data.result
        })
      }
      this.$axios.get('/wts/get/vas/' + this.projectid.projectid).then(response => {
        this.wtsVendorList = this.lodash.uniqBy(response.data.result,'vid');
      })
    },   
    sendFiltered() {

          var filteredElement=[];
         var estimatedObj={};
          this.vendorServiceListNew.forEach((outerElement, index, array) => {

            if( this.vendorEstimateServiceList.length>0){
            this.vendorEstimateServiceList.forEach((innerElement, index, array) => {

                 if(outerElement.serviceid==innerElement.serviceid)
                 {
                     estimatedObj={
                            "name":outerElement.servicename,
                            "uom1":outerElement.uom1,
                            "estimateQuantity1":innerElement.qty1,
                            "uom2":outerElement.uom1,
                            "estimateQuantity2":innerElement.qty2,
                            "uom3":outerElement.uom1,
                            "estimateQuantity3":innerElement.qty3,
                        };

                        filteredElement.push(estimatedObj);
                 }
                 else
                 {
                   estimatedObj={
                            "name":outerElement.servicename,
                            "uom1":outerElement.uom1,
                            "estimateQuantity1":0,
                            "uom2":outerElement.uom1,
                            "estimateQuantity2":0,
                            "uom3":outerElement.uom1,
                            "estimateQuantity3":0,
                        };

                        filteredElement.push(estimatedObj);
                 }
            });
          }
          else
          {
            estimatedObj={
                            "name":outerElement.servicename,
                            "uom1":outerElement.uom1,
                            "estimateQuantity1":0,
                            "uom2":outerElement.uom1,
                            "estimateQuantity2":0,
                            "uom3":outerElement.uom1,
                            "estimateQuantity3":0,
                        };
                        filteredElement.push(estimatedObj);
          }
        });

      return filteredElement;

    },
    getUoms(row){
      if(row.groupedUom[0]){
        this.uom1 = row.groupedUom[0].name;
      }
      if(row.groupedUom[1]){
        this.uom2 = row.groupedUom[1].name;
      }
      if(row.groupedUom[2]){
        this.uom3 = row.groupedUom[2].name;
      }

      this.serviceid = row.services[0].serviceid;
    },
    getNoUoms(row){
      if(row.uom1){
        this.uom1 = row.uom1;
      }
      if(row.uom2){
        this.uom2 = row.uom2;
      }
      if(row.uom3){
        this.uom3 = row.uom3;
      }

      this.serviceid = row.serviceid;
    },

    //This part is written by Raj Bhatta for New Service Estimate
  getVendors(){
      var url = '/service_estimate/get/vendor/project/' + this.projectid.projectid + '/afe/' + this.afes.pwcaid

      this.$axios.get(url).then(response => {
          this.vendorListNew=response.data;

      })
    },

    getVendorServices(){
      var vendorID=this.vendorEstimates.vid;
      var url = '/service_estimate/get/vendor/services/project/' + this.projectid.projectid + '/vid/' + vendorID+ '/afe/' + this.afes.pwcaid

      this.$axios.get(url).then(response => {
          this.vendorServiceListNew=response.data.vendorservice;
          this.vendorEstimateServiceList=response.data.serviceestimate;
          
      })
    },

    createEstimateUom1 ()
    {
         let data = JSON.stringify({
        "afenum": this.afes.afenum,
        "projectid": this.projectid.projectid,
        "serviceid": this.serviceid,
        "vendorid":this.vendorEstimates.vid,
        "qty1": this.uom1Quantity,
        "qty2": 0,
        "qty3": 0,
        "pwcaid": this.afes.pwcaid
      });
      this.$axios.post('service_estimate/create/new', data).then(response => {
       this.getServiceEstimateListForUpdate();
      }).catch(error => {
        this.$toasted.show('Error inside API for UOM 1 Update, Contact ADMIN for API verification'+error, {type: 'error', duration: '3000'})
      })
       this.$refs.addEstimateUom1.hide();
    },
    createEstimateUom2()
    {
        let data = JSON.stringify({
        "afenum": this.afes.afenum,
        "projectid": this.projectid.projectid,
        "serviceid": this.serviceid,
        "vendorid":this.vendorEstimates.vid,
        "qty1": 0,
        "qty2": this.uom2Quantity,
        "qty3": 0,
        "pwcaid": this.afes.pwcaid
      });
      this.$axios.post('service_estimate/create/new', data).then(response => {
        this.getServiceEstimateListForUpdate();
      }).catch(error => {
        this.$toasted.show('Error inside API for UOM 2 Update, Contact ADMIN for API verification'+error, {type: 'error', duration: '3000'})
      })
       this.$refs.addEstimateUom2.hide();

    },
    createEstimateUom3()
    {
        let data = JSON.stringify({
        "afenum": this.afes.afenum,
        "projectid": this.projectid.projectid,
        "serviceid": this.serviceid,
        "vendorid":this.vendorEstimates.vid,
        "qty1": 0,
        "qty2": 0,
        "qty3": this.uom3Quantity,
        "pwcaid": this.afes.pwcaid
      });
      this.$axios.post('service_estimate/create/new', data).then(response => {
       this.getServiceEstimateListForUpdate();
      }).catch(error => {
        this.$toasted.show('Error inside API for UOM 3 Update, Contact ADMIN for API verification'+error, {type: 'error', duration: '3000'})
      })

      this.$refs.addEstimateUom3.hide();
    },
    getServiceEstimateListForUpdate()
    {
      this.$axios.get('/service_estimate/get/vendor/services/estimate/project/'+ this.projectid.projectid+'/vid/'+this.vendorEstimates.vid+'/afe/'+this.afes.pwcaid).then(response => {
         this.vendorEstimateServiceList=response.data;
      }).catch(error => {
        this.$toasted.show('Error Getting Service Estimate from Server'+error, {type: 'error', duration: '3000'})
      })
    },
      Reload_getVendorServices(){
      this.vendorServiceListNew= [];
          this.vendorEstimateServiceList= [];

      var url = '/service_estimate/get/vendor/services/project/' + this.projectid.projectid + '/vid/' + this.vendorEstimates.vid + '/afe/' + this.afes.pwcaid

      this.$axios.get(url).then(response => {
          this.vendorServiceListNew=response.data.vendorservice;
          this.vendorEstimateServiceList=response.data.serviceestimate;
      })
    },
    // This part is the end of New Service Estimate
    clearImport(){
      this.successServices = [];
      this.serviceErrors = [];
      this.doc = [];

      const input = this.$refs.csvFile
      input.type = 'text'
      input.type = 'file'
      this.$refs.csvFile.value = null
    },
    upload (e) {
      const that = this
      const fileToLoad = e.target.files[0]

      const reader = new FileReader()
      reader.onload = fileLoadedEvent => {
        Papa.parse(fileLoadedEvent.target.result, {
          header: true,
          complete (results) {
            that.doc = results.data;
          },
          error (errors) {
          }
        })
      }
      reader.readAsText(fileToLoad)
    },
    importEstimates(services) {
      //let serviceSuccess = [];
      const that = this;
      var reMaped = [];

      for (var i = 0; i < services.length; i++) {
        for(var j=0; j<this.vendorServiceListNew.length; j++ )
        {
                if(services[i]['Name'] == this.vendorServiceListNew[j]['servicename'])
                {
                  var uom1 = 0;
                  var uom2 = 0;
                  var uom3 = 0;

                    if(services[i]['Estimate Quantity 1'] != undefined){
                      uom1 = services[i]['Estimate Quantity 1'];
                    }
                    if(services[i]['Estimate Quantity 2'] != undefined){
                      uom2 = services[i]['Estimate Quantity 2'];
                    }
                    if(services[i]['Estimate Quantity 3'] != undefined){
                      uom3 = services[i]['Estimate Quantity 3'];
                    }

                    reMaped.push({
                      qty1: uom1,
                      qty2: uom2,
                      qty3: uom3,
                      serviceid: this.vendorServiceListNew[j]['serviceid'],
                      projectid: this.projectid.projectid,
                      afenum: that.afes.afenum,
                      vid: this.vendorServiceListNew[j]['vid'],
                      companyid: this.vendorServiceListNew[j]['companyid'],
                      pwcaid: that.afes.pwcaid
                    })
                }
        }
      }

      this.$axios.post('/service_estimate/create/bulk', reMaped)
      .then(response => {

        this.$refs.importEstimates.hide();
        this.$toasted.show('Successfully Imported', {type: 'success', duration: '5000'})

        this.getVendorServices();

        const input = this.$refs.csvFile
        input.type = 'text'
        input.type = 'file'
        this.doc = [];
        this.$refs.csvFile.value = null

      }).catch(error => {
        console.log(error)
        this.$toasted.show('Import Failed'+error, {type: 'error', duration: '5000'})
      })
    },
  },
  mounted() {
    var user = this.$session.get('user');
    if(user == 'accountant'){
      user = 'manager'
    }
    if(user == 'pm'){
      user = 'wts'
    }
    console.log('er')

    this.$axios.get(user + '/get/projects').then(response => {
      this.projectData = response.data.result
    })
    .catch(error => {
      console.log(error)
    })
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
  .mx-input {
    padding: 20px 15px !important;
  }
  .edit {
    display: none;
  }
  .editing .edit {
    display: block;
  }
  .editing .edit.rt-d-inline
  {
    display: inline;
  }
  .editing .view {
    display: block;
  }
  // .editing .view .edit{
  //   display: block;
  // }
  .green {
    color: #4dbd74;
  }
  .danger {
    color: #db1616 !important;
  }

    #search_estimate{
      display: flex;
      button{
        margin-left: 20px;
      }
    }
    
</style>
